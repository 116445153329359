<template>
  <div class="outermost">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnMe">
        <div></div>
        <span>咨询问题</span>
      </div>
      <van-loading class="loading" v-if="refresh" size="24px"
        >加载中...</van-loading
      >
          <div
          >
            <div class="news-boder">
              <div class="news-boder-title">
                <img src="../../assets/img/me/info.png" alt="" />
                <div class="news-boder-title-left">
                  咨询问题
                </div>
                <div class="news-boder-title-time">2022-11-07 15:29:04</div>
              </div>
              <div class="underline"></div>
               <div class="news-boder-content">问:请问怎么军人认证？</div>
               <div class="news-boder-content">答:点击个人中心进去军人认证页面输入身份证认证。</div>
            </div>
          </div>
              <div
          >
            <div class="news-boder">
              <div class="news-boder-title">
                <img src="../../assets/img/me/info.png" alt="" />
                <div class="news-boder-title-left">
                  咨询问题
                </div>
                <div class="news-boder-title-time">2022-11-06 15:29:04</div>
              </div>
              <div class="underline"></div>
              <div class="news-boder-content">问:报名的活动怎么参加？</div>
               <div class="news-boder-content">答:报名后会提供一张二维码，到活动地点扫码。</div>
            </div>
          </div>
              <div
          >
            <div class="news-boder">
              <div class="news-boder-title">
                <img src="../../assets/img/me/info.png" alt="" />
                <div class="news-boder-title-left">
                  咨询问题
                </div>
                <div class="news-boder-title-time">2022-11-05 15:29:04</div>
              </div>
              <div class="underline"></div>
              <div class="news-boder-content">问:长辈模式怎么开启呢？</div>
               <div class="news-boder-content">答:点击个人中心把长辈默认按钮勾选即可。</div>
            </div>
          </div>
    </layout>
  </div>
</template>

<script>
import Layout from '@/components/layout.vue'
export default {
  components: {
    Layout
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      isLoading: false,
      pageNo: 1,
      pageSize: 10,
      refresh: false
    }
  },
  mounted() {},
  methods: {
    returnMe() {
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="less" scoped>
.outermost {
  background-color: #fff;

  .layout /deep/ .main {
    background: #f2f3f5;
    padding-bottom: 0;
    padding-top: 56px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
      color: #ffffff;
    }
  }
  .news-boder {
    position: relative;
    padding: 15px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 4px;
    .underline {
      margin-top: 13px;
      width: 325px;
      height: 1px;
      background: #dee0e8;
      border-radius: 4px;
    }
    .news-boder-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #12151b;
      img {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
      .news-boder-title-left {
        display: flex;
        align-items: flex-start;
        flex: 1;
        div {
          margin-right: 5px;
        }
      }
      .news-boder-title-time {
        font-size: 12px;
        color: #868a93;
      }
    }
    .news-boder-content {
      margin-top: 11px;
      font-size: 14px;
      color: #464a55;
      word-break: break-all;
    }
  }
  .dot {
    position: absolute;
    top: 30px;
    left: 5px;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 10px;
  }
}
</style>